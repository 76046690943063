import React from "react";
import {Col, Row} from "react-bootstrap";

function PageFooter(props) {
    return (
        <Row className="footer" id="PageFooter" >
            <Col style={{backgroundColor: "lightgray"}}>
                <span style={{backgroundColor: "lightgray"}}>Copyright 2020 Electronic Technology Inc.</span>
            </Col>
        </Row>
    );
}
export default PageFooter;