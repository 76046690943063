import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import GlobalContext from "../GlobalContext";
import { useForm } from "react-hook-form";

function AddUserModal(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [dataState, setDataState] = useState({
        loading: false
    });
    const [companyListState, setCompanyListState] = useState({
        loading: false,
        companyList: undefined,
    });

    useEffect(() => {
        setCompanyListState({ loading: true });
        const apiUrl = 'https://2x6a6o092g.execute-api.us-east-1.amazonaws.com/dev/customers';
        axios.get(apiUrl, {headers: { Authorization: 'Bearer ' + GlobalContext.LoggedInUser.signInUserSession.idToken.jwtToken}}).then((companies) => {
            const companyList = companies.data;
            setCompanyListState({ loading: false, companyList: companyList });
        });

    }, [setCompanyListState])

    const doSave = (data) => {
        setDataState({ loading: true });
        const apiUrl = `https://2x6a6o092g.execute-api.us-east-1.amazonaws.com/dev/users`;
        axios.post(apiUrl, JSON.stringify(data), {headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + GlobalContext.LoggedInUser.signInUserSession.idToken.jwtToken}}).then((user) => {
            setDataState({ loading: false });
            hideModal();
            props.RefreshUsers();
        });
    }

    const { register, handleSubmit, errors, formState } = useForm({ mode: "onChange" });

    const showModal = () => {
        setIsOpen(true);
    };

    const hideModal = () => {
        setIsOpen(false);
    };

    const Loading = () => {
        return(
            <div className="text-center" style={{height: "150px", margin: "auto"}}>
                <div className="spinner-border" role="status" style={{height: "100px", width: "100px", margin: "auto"}}>
                    <span className="sr-only">Loading Data...</span>
                </div>
            </div>
        );
    }

    return(
        <>
            <Button className="center" varient="primary" size="lg" onClick={ showModal }>Add User</Button>
            <div className="vspacer10"/>
            <Modal show={isOpen} onHide={hideModal} size="lg" backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title className="center">Add New User</Modal.Title>
                </Modal.Header>
                <Modal.Body className="center">
                    {dataState.loading ? <Loading/>:
                        <form id="newUserForm">
                            <div className="form-group">
                                <label>First Name</label>
                                <input type="text" className="form-control" placeholder="First name" name="firstname" ref={register({required: {value: true, message: "Name is required"}, maxLength: {value: 80, message: "Name is too long"}})} />
                                {errors.firstname && <small className="form-text text-muted">error: {errors.firstname.message}</small>}
                            </div>

                            <div className="form-group">
                                <label>Last Name</label>
                                <input type="text" className="form-control" placeholder="Last name" name="lastname" ref={register({required: {value: true, message: "Name is required"}, maxLength: {value: 80, message: "Name is too long"}})} />
                                {errors.lastname && <small className="form-text text-muted">error: {errors.lastname.message}</small>}
                            </div>

                            <div className="form-group">
                                <label>Email Address</label>
                                <input type="text" className="form-control" placeholder="Email" name="email" ref={register({required: {value: true, message: "Email Address is Required"}, pattern: { value: /^[a-zA-Z0-9.!s#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, message: "Invalid email address" }})} />
                                {errors.email && <small className="form-text text-muted">error: {errors.email.message}</small>}
                            </div>

                            <div className="form-group">
                                <label>Mobile Number</label>
                                <input type="tel" className="form-control" placeholder="Mobile number" name="phone" ref={register({required: {value: true, message: "Phone Number is Required"}, pattern: { value: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, message: "Invalid phone number" }})} />
                                {errors.phone && <small className="form-text text-muted">error: {errors.phone.message}</small>}
                            </div>

                            <div className="form-group">
                                <input type="checkbox" placeholder="Admin" name="Admin" ref={register} />
                                <label>&nbsp;&nbsp;Admin</label>
                            </div>
                            {GlobalContext.IsGlobalAdmin() && companyListState.companyList?.data &&
                            <div className="form-group">
                                <select name="cid" ref={register({ required: true })}>
                                    {companyListState.companyList.data.map((co) => {
                                        return(<option key={co.cid} value={co.cid}>{co.companyName}</option>);
                                    })}
                                </select>
                            </div>
                            }

                        </form>
                    }
                </Modal.Body>
                {!dataState.loading &&
                    <Modal.Footer className="center">
                        <button onClick={hideModal}>Cancel</button>
                        <input type="submit" form="newUserForm" onClick={handleSubmit(doSave)} disabled={!formState.isValid}/>
                    </Modal.Footer>
                }
            </Modal>
        </>
    );
}

export default AddUserModal