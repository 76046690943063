import React, { useEffect, useState } from "react";
import axios from "axios";
import GlobalContext from "../GlobalContext";
import CrabChart from "./CrabChart";

function CrabChartPage() {
    const [appState, setAppState] = useState({
        loading: false,
        crabList: undefined,
    });

    useEffect(() => {
        setAppState({ loading: true });
        const apiUrl = 'https://2x6a6o092g.execute-api.us-east-1.amazonaws.com/dev/crab';
        axios.get(apiUrl, {headers: { Authorization: 'Bearer ' + GlobalContext.LoggedInUser.signInUserSession.idToken.jwtToken}}).then((crabs) => {
            const crabList = crabs.data.data;
            setAppState({ loading: false, crabList: crabList });
        });
    }, [setAppState])

    function Loading(){
        return <div>Loading...</div>;
    }

    if(appState.loading || appState.crabList === undefined){
        return Loading();
    }
    return (
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 no-gutters" key="abc">
            {appState.crabList.map((crab) => {
                return (
                    <div className="col mb-0" key={crab.eui64}>
                        <CrabChart crabInfo={crab}/>
                    </div>
                );
            })}
        </div>
    );
}
export default CrabChartPage;