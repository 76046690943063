class GlobalContext {
    static LoggedInUser = null;

    static IsAdmin(){
        if(!this.LoggedInUser) return false;
        let groups = this.LoggedInUser.signInUserSession.idToken.payload["cognito:groups"];
        if (!groups) return false;
        return (groups.includes('Admin') || groups.includes('GlobalAdmin'));
    }

    static IsGlobalAdmin(){
        if(!this.LoggedInUser) return false;
        let groups = this.LoggedInUser.signInUserSession.idToken.payload["cognito:groups"];
        if (!groups) return false;
        return groups.includes('GlobalAdmin');
    }
}

export default GlobalContext;