import React from "react";
import {Jumbotron} from "react-bootstrap";

function WarningBanner() {
    return (
        <Jumbotron style={{align: "center"}}>
            <h2 align="center">
                <strong>WARNING</strong>
            </h2>
            <p align="center">
                This system is for the use of authorized users only. Individuals using this system without authority, or in excess of their authority, are subject to having all their activities on this system monitored and recorded by system personnel. Anyone using this system expressly consents to such monitoring and is advised that if such monitoring reveals possible evidence of criminal activity, system personnel may provide the evidence of such activity to law enforcement officials.
            </p>
        </Jumbotron>
    );
}
export default WarningBanner;