import React, { useState, useEffect } from "react";
import {
    HashRouter
} from "react-router-dom";

import AutoLogout from "./AutoLogout"

import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { Hub } from 'aws-amplify';
import {Auth} from "@aws-amplify/auth";
import { Container,  Col } from 'react-bootstrap';
import WarningBanner from "./WarningBanner";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import NavBar from "./NavBar";
import axios from "axios";
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import GlobalContext from "./GlobalContext";

Amplify.configure(aws_exports);

/*
Hub.listen('auth', (data) => {
    switch (data.payload.event) {
        case 'signIn':
            console.log('user signed in');
            break;
        case 'signUp':
            console.log('user signed up');
            break;
        case 'signOut':
            console.log('user signed out');
            break;
        case 'signIn_failure':
            console.log('user sign in failed');
            break;
        case 'configured':
            console.log('the Auth module is configured');
            break;
        default:
            console.log('Auth:  Unknown state');
    }
});
*/

async function GetLoggedInUser(){
    try {
        await Auth.currentSession();
        let u = await Auth.currentAuthenticatedUser();
        GlobalContext.LoggedInUser = u;
        return u;
    }
    catch(err){
        return null;
    }
}

async function refreshAuthLogic(failedRequest) {
    console.log("refreshAuthLogic")
    await GetLoggedInUser();
    failedRequest.response.config.headers['Authorization'] = 'Bearer ' + GlobalContext.LoggedInUser.signInUserSession.idToken.jwtToken;
    return Promise.resolve();
}

function fixAuthHeight () {
    //need to find a better way to do this.  This is extremely fragile and icky.
    const el = document.getElementsByClassName("hydrated");
    if (el.length > 0) {
        let len = el.length;
        for(let i = 0; i<len; i++){
            if(el[i].shadowRoot){
                if(el[i].shadowRoot.querySelectorAll("div.auth-container").length > 0) {
                    el[i].shadowRoot.querySelectorAll("div.auth-container")[0].style.minHeight = 0;
                    return;
                }
            }
        }
    }
    setTimeout(fixAuthHeight, 250); // if we didn't find the auth-container try again in 250 milliseconds
}

function Main () {
    const [user, setUser] = useState(null);
    useEffect(() => {
        createAuthRefreshInterceptor(axios, refreshAuthLogic);
        GetLoggedInUser().then(user => {
            if(user) {
                setUser(user);
            }
        })
        fixAuthHeight();
    }, []);

    Hub.listen('auth', (data) => {
        switch (data.payload.event) {
            case 'signIn':
                //console.log('user signed in');
                GlobalContext.LoggedInUser = data.payload.data;
                setUser(GlobalContext.LoggedInUser);
                break;
            case 'signOut':
                console.log('user signed out');
                setUser(null);
                GlobalContext.LoggedInUser = null;
                fixAuthHeight();
                break;
            default:
                break;
        }
    });
    //console.log(user);

    if(user == null) {
        return(
            <Container fluid>
                <PageHeader user={user}/>
                <Container fluid>
                    <Col id="subpage" style={{backgroundColor: "whitesmoke", align: "center"}}>
                        <Container fluid>
                            <br/>
                            <WarningBanner/>
                            <br/>
                            <AmplifyAuthenticator usernameAlias="email">
                                <AmplifySignIn slot="sign-in" hideSignUp />
                            </AmplifyAuthenticator>
                        </Container>
                    </Col>
                </Container>
                <PageFooter/>
            </Container>
        );
    }else{
        return (
            <HashRouter>
                <AutoLogout/>
                <Container fluid>
                    <PageHeader user={user}/>
                    <Col id="subpage" style={{backgroundColor: "whitesmoke", align: "center", textAlign: "center"}}>
                        <NavBar/>
                    </Col>
                    <PageFooter/>
                </Container>
            </HashRouter>
        );
    }
}

export default Main;