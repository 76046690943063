import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import GlobalContext from "../GlobalContext";
import { useForm } from "react-hook-form";

function AddCrabModal(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [dataState, setDataState] = useState({
        loading: false
    });
    const [companyListState, setCompanyListState] = useState({
        loading: false,
        companyList: undefined,
    });

    useEffect(() => {
        setCompanyListState({ loading: true });
        const apiUrl = 'https://2x6a6o092g.execute-api.us-east-1.amazonaws.com/dev/customers';
        axios.get(apiUrl, {headers: { Authorization: 'Bearer ' + GlobalContext.LoggedInUser.signInUserSession.idToken.jwtToken}}).then((companies) => {
            const companyList = companies.data;
            setCompanyListState({ loading: false, companyList: companyList });
        });

    }, [setCompanyListState])

    const doSave = (data) => {
        setDataState({ loading: true });
        const apiUrl = `https://2x6a6o092g.execute-api.us-east-1.amazonaws.com/dev/crab`;
        data.eui64 = data.eui64.toLowerCase();
        axios.post(apiUrl, JSON.stringify(data), {headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + GlobalContext.LoggedInUser.signInUserSession.idToken.jwtToken}}).then((crab) => {
            setDataState({ loading: false });
            hideModal();
            props.RefreshCrabs();
        });
    }

    const { register, handleSubmit, errors, formState, watch } = useForm({ mode: "onChange" });

    const showModal = () => {
        setIsOpen(true);
    }

    const hideModal = () => {
        setIsOpen(false);
    }

    const Loading = () => {
        return(
            <div className="text-center" style={{height: "150px", margin: "auto"}}>
                <div className="spinner-border" role="status" style={{height: "100px", width: "100px", margin: "auto"}}>
                    <span className="sr-only">Loading Data...</span>
                </div>
            </div>
        );
    }

    const watchWays = watch("ways");
    return(
        <>
            {GlobalContext.IsGlobalAdmin() &&
            <Button className="center" varient="primary" size="lg" onClick={showModal}>Add Crab</Button>
            }
            <div className="vspacer10"/>
            <Modal show={isOpen} onHide={hideModal} size="lg" backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title className="center">Add New Crab</Modal.Title>
                </Modal.Header>
                <Modal.Body className="center">
                    {dataState.loading ? <Loading/>:
                        <form id="newCrabForm">
                            <div className="form-group">
                                <label>eui64</label>
                                <input type="text" className="form-control" placeholder="eui64" name="eui64" ref={register({required: {value: true, message: "eui64 is required"}, pattern: { value: /^[0-9a-fA-F]{16}$/, message: "Invalid eui64 number" }})} />
                                {errors.eui64 && <small className="form-text text-muted">error: {errors.eui64.message}</small>}
                            </div>

                            <div className="form-group">
                                <label>Structure</label>
                                <input type="text" className="form-control" placeholder="Structure" name="structure" ref={register({maxLength: {value: 10, message: "Structure is too long"}})} />
                                {errors.structure && <small className="form-text text-muted">error: {errors.structure.message}</small>}
                            </div>

                            <div className="form-group">
                                <label>Area</label>
                                <input type="text" className="form-control" placeholder="Area" name="area" ref={register({maxLength: {value: 4, message: "Area is too long"}})} />
                                {errors.area && <small className="form-text text-muted">error: {errors.area.message}</small>}
                            </div>

                            <div className="form-group">
                                <label>Number of Ways</label>
                                &nbsp;
                                &nbsp;
                                <select name="ways" ref={register({ required: {value: true, message: "Area is required"}})}>
                                    <option value="5">5</option>
                                    <option value="7">7</option>
                                </select>
                                {errors.ways && <small className="form-text text-muted">error: {errors.ways.message}</small>}
                            </div>

                            <div className="form-group">
                                <label>Leg 1 Info</label>
                                <input type="text" className="form-control" placeholder="Leg 1" name="leg1Info" ref={register({maxLength: {value: 15, message: "Leg Info is too long"}})} />
                                {errors.leg1Info && <small className="form-text text-muted">error: {errors.leg1Info.message}</small>}
                            </div>

                            <div className="form-group">
                                <label>Leg 2 Info</label>
                                <input type="text" className="form-control" placeholder="Leg 2" name="leg2Info" ref={register({maxLength: {value: 15, message: "Leg Info is too long"}})} />
                                {errors.leg2Info && <small className="form-text text-muted">error: {errors.leg2Info.message}</small>}
                            </div>

                            <div className="form-group">
                                <label>Leg 3 Info</label>
                                <input type="text" className="form-control" placeholder="Leg 3" name="leg3Info" ref={register({maxLength: {value: 15, message: "Leg Info is too long"}})} />
                                {errors.leg3Info && <small className="form-text text-muted">error: {errors.leg3Info.message}</small>}
                            </div>

                            <div className="form-group">
                                <label>Leg 4 Info</label>
                                <input type="text" className="form-control" placeholder="Leg 4" name="leg4Info" ref={register({maxLength: {value: 15, message: "Leg Info is too long"}})} />
                                {errors.leg4Info && <small className="form-text text-muted">error: {errors.leg4Info.message}</small>}
                            </div>

                            {watchWays === "7" &&
                                <div className="form-group">
                                    <label>Leg 5 Info</label>
                                    <input type="text" className="form-control" placeholder="Leg 5" name="leg5Info" ref={register({maxLength: {value: 15, message: "Leg Info is too long"}})} />
                                    {errors.leg5Info && <small className="form-text text-muted">error: {errors.leg5Info.message}</small>}
                                </div>
                            }

                            {watchWays === "7" &&
                                <div className="form-group">
                                    <label>Leg 6 Info</label>
                                    <input type="text" className="form-control" placeholder="Leg 6" name="leg6Info" ref={register({maxLength: {value: 15, message: "Leg Info is too long"}})} />
                                    {errors.leg6Info && <small className="form-text text-muted">error: {errors.leg6Info.message}</small>}
                                </div>
                            }

                            <div className="form-group">
                                <label>Notes</label>
                                <input type="text" className="form-control" placeholder="Notes" name="notes" ref={register({maxLength: {value: 75, message: "Notes are too long"}})} />
                                {errors.notes && <small className="form-text text-muted">error: {errors.notes.message}</small>}
                            </div>

                            {GlobalContext.IsGlobalAdmin() && companyListState.companyList?.data &&
                            <div className="form-group">
                                <select name="cid" ref={register({ required: true })}>
                                    {companyListState.companyList.data.map((co) => {
                                        return(<option key={co.cid} value={co.cid}>{co.companyName}</option>);
                                    })}
                                </select>
                            </div>
                            }

                        </form>
                    }
                </Modal.Body>
                {!dataState.loading &&
                <Modal.Footer className="center">
                    <button onClick={hideModal}>Cancel</button>
                    <input type="submit" form="newCrabForm" onClick={handleSubmit(doSave)} disabled={!formState.isValid}/>
                </Modal.Footer>
                }
            </Modal>
        </>
    );
}

export default AddCrabModal