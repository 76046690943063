import React, { useEffect, useState } from "react";
import axios from "axios";
import GlobalContext from "../GlobalContext";
import CrabConfigTableItem from "./CrabConfigTableItem";
import AddCrabModal from "./AddCrabModal";

function CrabConfigTablePage() {
    const [appState, setAppState] = useState({
        loading: false,
        crabList: undefined,
        forceUpdate: false
    });

    useEffect(() => {
        setAppState({ loading: true, forceUpdate: false });
        const apiUrl = 'https://2x6a6o092g.execute-api.us-east-1.amazonaws.com/dev/crab';
        axios.get(apiUrl, {headers: { Authorization: 'Bearer ' + GlobalContext.LoggedInUser.signInUserSession.idToken.jwtToken}}).then((crabs) => {
            const crabList = crabs.data.data;
            setAppState({ loading: false, crabList: crabList, forceUpdate: false });
        });

    }, [setAppState, appState.forceUpdate])

    if(appState.loading || !appState.crabList){
        return(
            <div>Loading...</div>
        )
    }

    function RefreshCrabs(){
        setAppState({ loading: false, crabList: appState.crabList, forceUpdate: true });
    }

    return (
        <>
            <AddCrabModal className="center" RefreshCrabs = {() => RefreshCrabs()}/>
            <table className="crabTable">
                <thead>
                    <tr>
                        {GlobalContext.IsGlobalAdmin() &&
                            <th>Customer</th>
                        }
                        <th>Structure</th>
                        <th>Area</th>
                        <th>Ways</th>
                        <th>Leg 1</th>
                        <th>Leg 2</th>
                        <th>Leg 3</th>
                        <th>Leg 4</th>
                        <th>Leg 5</th>
                        <th>Leg 6</th>
                        <th>Notes</th>
                        <th>Crab ID</th>
                        {GlobalContext.IsAdmin() && <th></th> }
                    </tr>
                </thead>
                <tbody>
                    {appState.crabList.map((crab) => {
                        return (
                            <CrabConfigTableItem crab = {crab} key={crab.eui64}/>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
}
export default CrabConfigTablePage;