//basic concept borrowed from https://www.skptricks.com/2018/12/how-to-implement-auto-logout-client-side-in-react.html
import React from "react";
import {Auth} from "@aws-amplify/auth";
import Modal from "react-bootstrap/Modal";

const WARNTIMEOUT = 180;
const WARNCOUNTDOWN = 45;
class AutoLogout extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ShowWarnAlert: false, LogoutCountDown: WARNCOUNTDOWN };
    }

    componentDidMount() {
        this.events = [
            "load",
            "mousemove",
            "mousedown",
            "click",
            "scroll",
            "keypress"
        ];

        this.warn = this.warn.bind(this);
        this.logout = this.logout.bind(this);
        this.resetWarningTimeout = this.resetWarningTimeout.bind(this);
        this.cancelLogoutCount = this.cancelLogoutCount.bind(this);
        this.logoutCount = this.logoutCount.bind(this);
        for (let i in this.events) {
            window.addEventListener(this.events[i], this.resetWarningTimeout);
        }
        this.resetWarningTimeout();
    }

    componentWillUnmount() {
        this.destroy();
    }

    resetWarningTimeout() {
        if(!this.state.ShowWarnAlert) {
            if (this.warnTimeout) clearTimeout(this.warnTimeout);
            if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
            this.warnTimeout = setTimeout(this.warn, WARNTIMEOUT * 1000);
            this.setState({ShowWarnAlert: false, LogoutCountDown: WARNCOUNTDOWN});
        }
    }

    resetLogoutTimeout() {
        if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
        this.logoutTimeout = setTimeout(this.logoutCount, 1 * 1000);
    }

    cancelLogoutCount(){
        if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
        this.setState({ShowWarnAlert: false, LogoutCountDown: WARNCOUNTDOWN});
        this.resetWarningTimeout();
    }

    warn() {
        this.startLogoutCount();
    }

    startLogoutCount(){
        this.setState({ ShowWarnAlert: true, LogoutCountDown: WARNCOUNTDOWN });
        if (this.warnTimeout) clearTimeout(this.warnTimeout);
        this.resetLogoutTimeout();
    }

    logoutCount(){
        let tmp = this.state.LogoutCountDown - 1;
        this.setState({ ShowWarnAlert: true, LogoutCountDown: tmp });
        if(this.state.LogoutCountDown <= 0){
            this.logout();
            this.setState({ ShowWarnAlert: false, LogoutCountDown: WARNCOUNTDOWN });
        }else{
            this.resetLogoutTimeout();
        }
    }

    async logout() {
        try {
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    destroy() {
        if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
        if (this.warnTimeout) clearTimeout(this.warnTimeout);
        for (let i in this.events) {
            window.removeEventListener(this.events[i], this.resetWarningTimeout);
        }
    }

    render() {
        return (
            <Modal show={this.state.ShowWarnAlert} onHide={this.cancelLogoutCount} size="lg">
                <Modal.Header>
                    <Modal.Title className="center">Idle warning</Modal.Title>
                </Modal.Header>
                <Modal.Body className="center">
                    You will be logged out in {this.state.LogoutCountDown} seconds...
                </Modal.Body>
                <Modal.Footer className="center">
                    <button onClick={this.cancelLogoutCount}>Remain Logged In</button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default AutoLogout;