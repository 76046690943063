import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { faPen, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import GlobalContext from "../GlobalContext";
import Button from "react-bootstrap/Button";

function CrabConfigTableItem(props) {
    const [appState, setAppState] = useState({
        loading: false,
        crabInfo: props.crab,
        editing: false
    });

    function doEdit(){
        console.log("doEdit")
        setAppState({ loading: false, editing: true, crabInfo: appState.crabInfo });
        reset(appState.crabInfo);
    }

    function doCancel(){
        console.log("doCancel")
        setAppState({ loading: false, editing: false, crabInfo: appState.crabInfo });
    }

    function doSave(data){
        console.log("doSave")
        setAppState({ loading: true, editing: false, crabInfo: appState.crabInfo });
        const apiUrl = `https://2x6a6o092g.execute-api.us-east-1.amazonaws.com/dev/crab/${data.eui64}`;
        axios.post(apiUrl, JSON.stringify(data), {headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + GlobalContext.LoggedInUser.signInUserSession.idToken.jwtToken}}).then((crabs) => {
            console.log("Data Saved")
            setAppState({ loading: false, editing: false, crabInfo: data });
        });
    }

    function displayItems(){
        return (
            <tr key={appState.crabInfo.eui64}>
                {GlobalContext.IsGlobalAdmin() &&
                    <td>{appState.crabInfo.companyName}</td>
                }
                <td>{appState.crabInfo.structure}</td>
                <td>{appState.crabInfo.area}</td>
                <td>{appState.crabInfo.ways}</td>
                <td>{appState.crabInfo.leg1Info}</td>
                <td>{appState.crabInfo.leg2Info}</td>
                <td>{appState.crabInfo.leg3Info}</td>
                <td>{appState.crabInfo.leg4Info}</td>
                {(appState.crabInfo.ways === 7)
                    ? <td>{appState.crabInfo.leg5Info}</td>
                    : <td>&nbsp;</td>
                }
                {(appState.crabInfo.ways === 7)
                    ? <td>{appState.crabInfo.leg6Info}</td>
                    : <td>&nbsp;</td>
                }
                <td>{appState.crabInfo.notes}</td>
                <td>{appState.crabInfo.eui64}</td>
                <td>
                    {appState.loading
                        ?
                        <div className="text-center" style={{margin: "auto"}}>
                            <div className="spinner-border" role="status" style={{margin: "auto"}}>
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                        :
                        GlobalContext.IsAdmin() && <span id='clickable'><FontAwesomeIcon icon={faPen} onClick={() => doEdit()}/></span>
                    }
                </td>
            </tr>
        );
    }


    const { register, handleSubmit, formState, reset } = useForm({ mode: "onChange" });
    function editItems(){
        return (
            <tr key={appState.crabInfo.eui64}>
                {GlobalContext.IsGlobalAdmin() &&
                    <td>{appState.crabInfo.companyName}</td>
                }
                <input type="hidden" ref={register} name="eui64" />
                <td><input type="text" className="form-control" placeholder="Structure" name="structure" ref={register({required: {value: true, message: "Structure is required"}, maxLength: {value: 10, message: "Structure is too long"}})} /></td>
                <td><input type="text" className="form-control" placeholder="Area" name="area" ref={register({required: {value: true, message: "Area is required"}, maxLength: {value: 4, message: "Area is too long"}})} /></td>
                <td>{appState.crabInfo.ways}</td>
                <td><input type="text" className="form-control" placeholder="Leg 1" name="leg1Info" ref={register({maxLength: {value: 15, message: "Leg 1 info is too long"}})} /></td>
                <td><input type="text" className="form-control" placeholder="Leg 2" name="leg2Info" ref={register({maxLength: {value: 15, message: "Leg 2 info is too long"}})} /></td>
                <td><input type="text" className="form-control" placeholder="Leg 3" name="leg3Info" ref={register({maxLength: {value: 15, message: "Leg 3 info is too long"}})} /></td>
                <td><input type="text" className="form-control" placeholder="Leg 4" name="leg4Info" ref={register({maxLength: {value: 15, message: "Leg 4 info is too long"}})} /></td>
                {(appState.crabInfo.ways === 7)
                    ? <td><input type="text" className="form-control" placeholder="Leg 5" name="leg5Info" ref={register({maxLength: {value: 15, message: "Leg 5 info is too long"}})} /></td>
                    : <td>&nbsp;</td>
                }
                {(appState.crabInfo.ways === 7)
                    ? <td><input type="text" className="form-control" placeholder="Leg 6" name="leg6Info" ref={register({maxLength: {value: 15, message: "Leg 6 info is too long"}})} /></td>
                    : <td>&nbsp;</td>
                }
                <td><input type="text" className="form-control" placeholder="Notes" name="notes" ref={register({maxLength: {value: 75, message: "Notes are too long"}})} /></td>
                <td>{appState.crabInfo.eui64}</td>
                <td>
                    <Button type="submit" variant="link" style={{color: "black"}} form="newUserForm" disabled={!formState.isValid} onClick={handleSubmit(doSave)}><FontAwesomeIcon icon={faSave} className="fa-lg" onClick={handleSubmit}/></Button>
                    &nbsp; <span id='clickable' ><FontAwesomeIcon icon={faTimes} className="fa-lg" onClick={() => doCancel()}/></span>
                </td>
            </tr>
        );
    }

    if(appState.editing){
        return editItems();
    }else{
        return displayItems();
    }
}
export default CrabConfigTableItem;