import React, {useState, useRef} from "react";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import format from 'date-fns/format';
import { DateRange } from 'react-date-range';
import GlobalContext from "../GlobalContext";


function ExportDataModal(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [dataState, setDataState] = useState({
        loading: false
    });
    let dateRangeRef = useRef ([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const showModal = () => {
        setIsOpen(true);
    };

    const hideModal = () => {
        setIsOpen(false);
    };

    const onSave = () => {
        setDataState({ loading: true });
        const apiUrl = `https://2x6a6o092g.execute-api.us-east-1.amazonaws.com/dev/crab/${props.crab.eui64}/export`;
        let startDate = new Date();
        let endDate = new Date();
        if(dateRangeRef.startDate){
            startDate = dateRangeRef.startDate;
        }
        if(dateRangeRef.endDate) {
            endDate = dateRangeRef.endDate;
        }
        startDate.setHours(0,0,0,0);
        endDate.setHours(23,59,59,999);
        const fname = `Crab_${props.crab.structure}_${props.crab.eui64}_${format(startDate, 'yyyyMMdd')}_${format(endDate, 'yyyyMMdd')}.csv`
        axios.get(apiUrl, {headers: { Authorization: 'Bearer ' + GlobalContext.LoggedInUser.signInUserSession.idToken.jwtToken}, params: { startDate: startDate, endDate: endDate, fname: fname}}).then((dataExport) => {
            saveAs(dataExport.data.url);
            setDataState({ loading: false });
            hideModal();
        });
    }

    function saveAs(uri, filename) {
        const link = document.createElement('a');
        document.body.appendChild(link); // Firefox requires the link to be in the body
        link.download = filename;
        link.href = uri;
        link.click();
        document.body.removeChild(link); // remove the link when done
    }

    const Loading = () => {
        return(
            <div className="text-center" style={{height: "150px", margin: "auto"}}>
                <div className="spinner-border" role="status" style={{height: "100px", width: "100px", margin: "auto"}}>
                    <span className="sr-only">Loading Data...</span>
                </div>
            </div>
        );
    }

    const PickDateRange = () => {
        let [dateRange, setDateRange] = useState ([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection'
            }
        ]);
        function handleSelect(ranges){
            console.log("handle Select: " + JSON.stringify(ranges))
            if(ranges.selection) {
                setDateRange([ranges.selection]);
                dateRangeRef = ranges.selection;
            }
        }
        return(
            <DateRange
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                direction="horizontal"
                ranges={dateRange}
                months={2}
                onChange={handleSelect}
                maxDate = {new Date()}
            />
        );
    }

    return(
        <>
            <span id='clickable'><FontAwesomeIcon icon={faSave} className="fa-lg" onClick={ showModal }/></span>
            <Modal show={isOpen} onHide={hideModal} size="lg" backdrop="static" keyboard={false}>
                <Modal.Header>
                    {!dataState.loading
                        ?
                        <Modal.Title className="center">Select Date Range To Export From {props.crab.structure} (Crab {props.crab.eui64})</Modal.Title>
                        :
                        <Modal.Title className="center">Loading requested data...</Modal.Title>
                    }
                </Modal.Header>
                <Modal.Body className="center">
                    {dataState.loading ? <Loading/>: <PickDateRange/>}
                </Modal.Body>
                {!dataState.loading
                    ?
                    <Modal.Footer className="center">
                        <button onClick={hideModal}>Cancel</button>
                        <button onClick={onSave}>Save</button>
                    </Modal.Footer>
                    :
                    <></>
                }
            </Modal>
        </>
    );
}

export default ExportDataModal