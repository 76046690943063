import React, { useEffect, useState } from "react";
import axios from "axios";
import GlobalContext from "../GlobalContext";

function LoginHistoryPage() {
    const [appState, setAppState] = useState({
        loading: false,
        histList: undefined,
    });

    useEffect(() => {
        setAppState({loading: true});
        const apiUrl = 'https://2x6a6o092g.execute-api.us-east-1.amazonaws.com/dev/loginhistory';
        axios.get(apiUrl, {headers: {Authorization: 'Bearer ' + GlobalContext.LoggedInUser.signInUserSession.idToken.jwtToken}}).then((hist) => {
            const histList = hist.data.data;
            setAppState({loading: false, histList: histList});
        })
            .catch(err => {
                if (err.response) {
                    // client received an error response (5xx, 4xx)
                } else if (err.request) {
                    // client never received a response, or request never left
                } else {
                    // anything else
                }
            })
    }, [setAppState])

    if(appState.loading || !appState.histList){
        return(
            <div>Loading...</div>
        )
    }

    return (
        <table className="crabTable">
            <thead>
            <tr>
                {GlobalContext.IsGlobalAdmin() &&
                <th>Customer</th>
                }
                <th>Time</th>
                <th>email</th>
                <th>Last</th>
                <th>First</th>
            </tr>
            </thead>
            <tbody>
            {appState.histList.map((hist) => {
                return (
                    <tr key={hist.loginId}>
                        {GlobalContext.IsGlobalAdmin() &&
                        <td>{hist.companyName}</td>
                        }
                        <td>{hist.ts}</td>
                        <td>{hist.email}</td>
                        <td>{hist.fname}</td>
                        <td>{hist.lname}</td>
                    </tr>
                );
            })}
            </tbody>
        </table>
    );
}
export default LoginHistoryPage;