import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Button from 'react-bootstrap/Button';
import { faPen, faSave, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import GlobalContext from "../GlobalContext";

function UserItem(props) {
    const [appState, setAppState] = useState({
        loading: false,
        userInfo: props.user,
        editing: false
    });

    function doEdit(){
        setAppState({ loading: false, editing: true, userInfo: appState.userInfo });
        reset(appState.userInfo)
    }

    function doCancel(){
        setAppState({ loading: false, editing: false, userInfo: appState.userInfo });
    }

    const doSave = (data) => {
        setAppState({ loading: true, editing: false, userInfo: appState.userInfo });
        const apiUrl = `https://2x6a6o092g.execute-api.us-east-1.amazonaws.com/dev/users/${data.Username}`;
        axios.post(apiUrl, JSON.stringify(data), {headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + GlobalContext.LoggedInUser.signInUserSession.idToken.jwtToken}}).then((user) => {
            appState.userInfo.Admin = data.Admin;
            appState.userInfo.Enabled = data.Enabled;
            appState.userInfo.family_name = data.family_name;
            appState.userInfo.given_name = data.given_name;
            appState.userInfo.phone_number = data.phone_number;
            setAppState({ loading: false, editing: false, userInfo: appState.userInfo });
        });
    }

    function doDelete(){
        console.log("doDelete")
        setAppState({ loading: true, editing: false, userInfo: appState.userInfo });
        const apiUrl = `https://2x6a6o092g.execute-api.us-east-1.amazonaws.com/dev/users/${appState.userInfo.Username}`;
        axios.delete(apiUrl, {headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + GlobalContext.LoggedInUser.signInUserSession.idToken.jwtToken}}).then((user) => {
            //setAppState({ loading: false, editing: false, userInfo: null });
            props.RefreshUsers();
        });
    }

    function displayUser(){
        if(!appState.userInfo){
            return (<></>);
        }
        appState.userInfo.Admin = appState.userInfo.GroupName === "Admin" || appState.userInfo.GroupName === "GlobalAdmin";
        return (
            <tr key={appState.userInfo.Username}>
                {GlobalContext.IsGlobalAdmin() &&
                    <td>{appState.userInfo["company"]}</td>
                }
                <td>{appState.userInfo.email}</td>
                <td>{appState.userInfo.given_name}</td>
                <td>{appState.userInfo.family_name}</td>
                <td>{appState.userInfo.phone_number}</td>
                <td>{appState.userInfo.GroupName === "GlobalAdmin" ? <>GlobalAdmin</> : <input type="checkbox" readOnly checked={appState.userInfo.Admin}/>}</td>
                <td><input type="checkbox" readOnly checked={appState.userInfo.Enabled}/></td>
                <td>{appState.userInfo.UserStatus}</td>
                <td>{appState.userInfo.UserCreateDate}</td>
                <td>
                    {appState.loading
                        ?
                        <div className="text-center" style={{margin: "auto"}}>
                            <div className="spinner-border" role="status" style={{margin: "auto"}}>
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                        :
                        <span id='clickable'><FontAwesomeIcon icon={faPen} onClick={() => doEdit()}/></span>
                    }
                </td>
            </tr>
        );
    }

    const { register, handleSubmit, formState, reset } = useForm({ mode: "onChange" });
    function editUser(){
        return (
            <tr key={appState.userInfo.Username}>
                {GlobalContext.IsGlobalAdmin() &&
                    <td>{appState.userInfo["company"]}</td>
                }
                <input type="hidden" ref={register} name="Username" />
                <input type="hidden" ref={register} name="email" />
                <td>{appState.userInfo.email}</td>
                <td><input type="text" className="form-control" placeholder="First name" name="given_name" ref={register({required: {value: true, message: "Name is required"}, maxLength: {value: 80, message: "Name is too long"}})} /></td>
                <td><input type="text" className="form-control" placeholder="Last name" name="family_name" ref={register({required: {value: true, message: "Name is required"}, maxLength: {value: 80, message: "Name is too long"}})} /></td>
                <td><input type="tel" className="form-control" placeholder="Mobile number" name="phone_number" ref={register({required: {value: true, message: "Phone Number is Required"}, pattern: { value: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, message: "Invalid phone number" }})} /></td>
                <td>{(appState.userInfo.GroupName!=="GlobalAdmin") ? <input type="checkbox" placeholder="Admin" name="Admin" ref={register} />: appState.userInfo.GroupName} </td>
                <td>{(appState.userInfo.email===GlobalContext.LoggedInUser.attributes.email) ? <input type="checkbox" readOnly checked={appState.userInfo.Enabled}/> : <input type="checkbox" placeholder="Enabled" name="Enabled" ref={register} />}</td>
                <td>{appState.userInfo.UserStatus}</td>
                <td>{appState.userInfo.UserCreateDate}</td>
                <td>
                    <Button type="submit" variant="link" style={{color: "black"}} form="newUserForm" disabled={!formState.isValid} onClick={handleSubmit(doSave)}><FontAwesomeIcon icon={faSave} className="fa-lg" onClick={handleSubmit}/></Button>
                    <Button variant="link" style={{color: "black"}} onClick={handleSubmit(doCancel)}><FontAwesomeIcon icon={faTimes} className="fa-lg" onClick={handleSubmit}/></Button>
                    {!appState.userInfo.Enabled && <Button variant="link" style={{color: "black"}} onClick={handleSubmit(doDelete)}><FontAwesomeIcon icon={faTrash} className="fa-lg" onClick={handleSubmit}/></Button>}
                </td>
            </tr>
        );
    }

    if(appState.editing){
        return editUser();
    }else{
        return displayUser();
    }
}
export default UserItem;