/* eslint-disable */
const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:ce18aacf-0721-4602-a278-d62a9c64b187",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Ri9Wq8QcQ",
    "aws_user_pools_web_client_id": "6fqk1jq29b1f0548gfd0kc40l6",
    "oauth": {},
    "aws_cloud_logic_custom": []
};


export default awsmobile;
