import React, {useEffect} from "react";
import {NavLink, Route} from "react-router-dom";
import CrabChartPage from "./CrabCharts/CrabChartPage";
import CrabDataTablePage from "./CrabDataTable/CrabDataTablePage";
import CrabConfigTablePage from "./CrabConfigTable/CrabConfigTablePage";
import UserListPage from "./UserList/UserListPage";
import LoginHistoryPage from "./LoginHistory/LoginHistoryPage";
import GlobalContext from "./GlobalContext";

function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}


function NavBar(props) {
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    });

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            try {
                if (window) {
                    let contentHeight = window.innerHeight;
                    contentHeight -= document.getElementById('PageHeader')?.offsetHeight;
                    contentHeight -= document.getElementById('PageFooter')?.offsetHeight;
                    let el = document.getElementById('NavHeader');
                    contentHeight -= el?.offsetHeight;
                    let style = window.getComputedStyle ? getComputedStyle(el, null) : null;
                    if (style) {
                        let marginTop = parseInt(style.marginTop) || 0;
                        let marginBottom = parseInt(style.marginBottom) || 0;
                        contentHeight -= marginTop + marginBottom;
                    }
                    contentHeight -= 37;
                    setDimensions({
                        height: contentHeight,
                        width: window.innerWidth
                    })
                }
            }
            catch(err){

            }
        }, 250)

        window.addEventListener('resize', debouncedHandleResize)
        debouncedHandleResize();
        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    }, []);
    return (
        <>
            <ul className="header" id="NavHeader">
                <li><NavLink exact to="/">Crab Data</NavLink></li>
                <li><NavLink to="/CrabChart">Charts</NavLink></li>
                <li><NavLink to="/CrabConfigTable">Crab Config</NavLink></li>
                {GlobalContext.IsAdmin() &&
                    <>
                        <li><NavLink to="/UserListPage">User List</NavLink></li>
                        <li><NavLink to="/LoginHistoryPage">Login History</NavLink></li>
                    </>
                }
            </ul>
            <div className="contentBox"  style={{height: dimensions.height}}>
                <div className="content"  style={{overflowY: "auto", height: dimensions.height-31}}>
                    <Route exact path="/" component={CrabDataTablePage}/>
                    <Route path="/CrabChart" component={CrabChartPage}/>
                    <Route path="/CrabConfigTable" component={CrabConfigTablePage}/>
                    {GlobalContext.IsAdmin() &&
                        <>
                            <Route path="/UserListPage" component={UserListPage}/>
                            <Route path="/LoginHistoryPage" component={LoginHistoryPage}/>
                        </>
                    }
                </div>
            </div>
        </>
    );
}
export default NavBar;