import React, { useEffect, useState } from "react";
import axios from "axios";
import GlobalContext from "../GlobalContext";
import UserItem from "../UserList/UserItem";
import AddUserModal from "./AddUserModal";

function UserListPage() {
    const [appState, setAppState] = useState({
        loading: false,
        userList: undefined,
        forceUpdate: false
    });

    useEffect(() => {
        setAppState({ loading: true, forceUpdate: false});
        const apiUrl = 'https://2x6a6o092g.execute-api.us-east-1.amazonaws.com/dev/users';
        axios.get(apiUrl, {headers: { Authorization: 'Bearer ' + GlobalContext.LoggedInUser.signInUserSession.idToken.jwtToken}}).then((users) => {
            const userList = users.data.users;
            setAppState({ loading: false, userList: userList, forceUpdate: false });
        });

    }, [setAppState, appState.forceUpdate])

    if(!appState.userList){
        return(
            <div>Loading...</div>
        )
    }

    function RefreshUsers(){
        setAppState({ loading: false, userList: appState.userList, forceUpdate: true });
    }

    return (
        <>
        <AddUserModal className="center" RefreshUsers = {() => RefreshUsers()}/>
        <table className="crabTable">
            <thead>
                <tr>
                    {GlobalContext.IsGlobalAdmin() &&
                        <th>Customer</th>
                    }
                    <th>Email</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Phone</th>
                    <th>Admin</th>
                    <th>Enabled</th>
                    <th>Status</th>
                    <th>Created</th>
                    <th>Edit</th>
                </tr>
            </thead>
            <tbody>
                {appState.userList.map((user) => {
                    return (
                        <UserItem user = {user} key={user.Username} RefreshUsers = {() => RefreshUsers()}/>
                    );
                })}
            </tbody>
        </table>
        </>
    );
}
export default UserListPage;