import React, { useEffect, useState } from "react";
import axios from "axios";
import GlobalContext from "../GlobalContext";
import ExportDataModal from "./ExportDataModal"

function CrabDataTablePage() {
    const [appState, setAppState] = useState({
        loading: false,
        crabList: undefined,
    });

    useEffect(() => {
        setAppState({loading: true});
        const apiUrl = 'https://2x6a6o092g.execute-api.us-east-1.amazonaws.com/dev/crab';
        axios.get(apiUrl, {headers: {Authorization: 'Bearer ' + GlobalContext.LoggedInUser.signInUserSession.idToken.jwtToken}}).then((crabs) => {
            const crabList = crabs.data.data;
            setAppState({loading: false, crabList: crabList});
        })
        .catch(err => {
            if (err.response) {
                // client received an error response (5xx, 4xx)
            } else if (err.request) {
                // client never received a response, or request never left
            } else {
                // anything else
            }
        })
    }, [setAppState])

    if(appState.loading || !appState.crabList){
        return(
            <div>Loading...</div>
        )
    }

    return (
        <table className="crabTable">
            <thead>
                <tr>
                    {GlobalContext.IsGlobalAdmin() &&
                        <th>Customer</th>
                    }
                    <th>Structure</th>
                    <th>Area</th>
                    <th>Timestamp</th>
                    <th>I1</th>
                    <th>I2</th>
                    <th>I3</th>
                    <th>I4</th>
                    <th>I5</th>
                    <th>I6</th>
                    <th>Temp 1</th>
                    <th>Temp 2</th>
                    <th>Notes</th>
                    <th>Crab ID</th>
                    <th>Export Data</th>
                </tr>
            </thead>
            <tbody>
                {appState.crabList.map((crab) => {
                    return (
                        <tr key={crab.eui64}>
                            {GlobalContext.IsGlobalAdmin() &&
                                <td>{crab.companyName}</td>
                            }
                            <td>{crab.structure}</td>
                            <td>{crab.area}</td>
                            <td>{crab.ts}</td>
                            <td>{crab.I1}</td>
                            <td>{crab.I2}</td>
                            <td>{crab.I3}</td>
                            <td>{crab.I4}</td>
                            <td>{crab.I5}</td>
                            <td>{crab.I6}</td>
                            <td>{crab.T1}</td>
                            <td>{crab.T2}</td>
                            <td>{crab.notes}</td>
                            <td>{crab.eui64}</td>
                            <td><ExportDataModal crab={crab}/></td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}
export default CrabDataTablePage;