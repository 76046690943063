import React from "react";
import {Col, Row} from "react-bootstrap";
import {Auth} from "@aws-amplify/auth";

async function signOut() {
    try {
        await Auth.signOut();
    } catch (error) {
        console.log('error signing out: ', error);
    }
}

function PageHeader(props) {
    return (
        <Row id="PageHeader">
            <Col style={{backgroundColor: "lightgray", height: "100px", align: "left", lineHeight: "100px"}}>
                <img src='img/ETI_LOGO.gif' alt="ETI LOGO" height="67" width="174"/>
            </Col>
            <Col style={{backgroundColor: "lightgray", height: "100px", align: "center", lineHeight: "100px"}}>
                <h2 align="center" style={{height: "100px", lineHeight: "100px"}}><strong>ETI Smart Crab</strong></h2>
            </Col>
            <Col style={{backgroundColor: "lightgray", height: "100px", align: "right", lineHeight: "100px", textAlign: "right"}}>
                {props.user && <button type="button" className="link-button" onClick={() => signOut()}>Logout [{props.user?.attributes?.email}]</button>}
            </Col>
        </Row>
    );
}
export default PageHeader;