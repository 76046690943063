import React, {useEffect, useState} from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import axios from "axios";
import GlobalContext from "../GlobalContext";

function CrabChart(props) {
    const [chartState, setChartState] = useState({
        loading: false,
        crabData: undefined,
        Ymax: findYMax()
    });

    useEffect(() => {
        setChartState({loading: true, crabData: undefined});
        const apiUrl = `https://2x6a6o092g.execute-api.us-east-1.amazonaws.com/dev/crab/${props.crabInfo.eui64}`;
        axios.get(apiUrl, {headers: {Authorization: 'Bearer ' + GlobalContext.LoggedInUser.signInUserSession.idToken.jwtToken}}).then((responseData) => {
            const crabData = responseData.data.data;
            setChartState({loading: false, crabData: crabData, Ymax: findYMax(crabData)});
        });
    }, [setChartState, props.crabInfo.eui64])

    const formatXAxis = (tickItem) => {
        let tstr = tickItem.split(" ")[1].split(".")[0];
        return tstr.substr(0, tstr.length - 3);
    }

    function findYMax(data){
        if(!data){
            return {Imax: 0, Tmax: 0};
        }
        return data.reduce(function (max, dat){
            let Imax;
            if(dat.I5) {
                Imax = Math.max(dat.I1, dat.I2, dat.I3, dat.I4, dat.I5, dat.I6);
            }else{
                Imax = Math.max(dat.I1, dat.I2, dat.I3, dat.I4);
            }
            const Tmax = Math.max(dat.T1, dat.T2);
            //console.log(`Imax: ${Imax} Tmax: ${Tmax} max.Imax: ${max.Imax} max.Tmax: ${max.Tmax}`)
            if(Imax > max.Imax){
                max.Imax = Imax;
            }
            if(Tmax > max.Tmax){
                max.Tmax = Tmax;
            }
            return max;
        },{Imax: 0, Tmax: 0});
    }

    function chartOrSpinner(){
        if(chartState.loading || chartState.crabData === undefined){
            return(
                <div className="text-center" style={{height: "150px", margin: "auto"}}>
                    <div className="spinner-border" role="status" style={{height: "100px", width: "100px", margin: "auto"}}>
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )
        }else {
            return (
                <LineChart data={chartState.crabData}
                           style={{display: "block", marginLeft: "auto", marginRight: "auto"}}>
                    <YAxis type='number' yAxisId={0} domain={[0, Math.round(chartState.Ymax.Imax*1.1)]}/>
                    <XAxis dataKey='time' tickFormatter={formatXAxis}/>
                    {/*<Tooltip position={{y: 200}}/>*/}
                    <CartesianGrid strokeDasharray="3 3"/>
                    <Line dataKey='I1' stroke='#A52A2A' dot={false} isAnimationActive={false} strokeWidth={2}
                          yAxisId={0}/>
                    <Line dataKey='I2' stroke='#FF0000' dot={false} isAnimationActive={false} strokeWidth={2}
                          yAxisId={0}/>
                    <Line dataKey='I3' stroke='#FFA500' dot={false} isAnimationActive={false} strokeWidth={2}
                          yAxisId={0}/>
                    <Line dataKey='I4' stroke='#EEEE55' dot={false} isAnimationActive={false} strokeWidth={2}
                          yAxisId={0}/>
                    <Line dataKey='I5' stroke='#008000' dot={false} isAnimationActive={false} strokeWidth={2}
                          yAxisId={0}/>
                    <Line dataKey='I6' stroke='#0000FF' dot={false} isAnimationActive={false} strokeWidth={2}
                          yAxisId={0}/>
                </LineChart>
            );
        }
    }

    return (
        <div className="card">
            <div className="card-header">
                Crab ID:  {props.crabInfo.eui64}<br/>
                Structure:  {props.crabInfo.structure}<br/>
                Area:  {props.crabInfo.area}
            </div>
            <div className="card-body">
                <div className="line-charts">
                    <ResponsiveContainer width="100%" height={150}>
                        {chartOrSpinner()}
                    </ResponsiveContainer>
                </div>
            </div>
            {/*<div className="card-footer">Footer</div>*/}
        </div>
    );
}

export default CrabChart;
